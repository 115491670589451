<template>
    <div class="tribeify-header-container">
        <div class="tribeify-header">
            <div class="tribeify-title-wrapper">
                <div>
                    <div class="cart-icon" @click="goShopping()">
                        <img :src="require('../../assets/images/icon/cart-icon.png')"/>
                    </div>

                    <div class="shop-logo">
                        <img :src="shopLogo"/>
                    </div>
                    <div class="tribeify-sub-title">Membership Rewards</div>

                    <div class="tribeify-profile">
                        <div class="tribeify-profile-image medium">
                            <div class="image-holder">
                            <img
                                :src="userAvatar ? userAvatar : defaultAvatar"
                                @click="selectFile"
                                alt="user avatar"
                                class="cover-image"
                            />
                            <input
                                type="file"
                                hidden
                                id="imageUpload"
                                accept="image/*"
                                @change="uploadImage($event.target.files)"
                                ref="avatarImageSelect"
                                placeholder=""
                                name="filename"
                            />
                            </div>
                        </div>
                        <img :src="require('../../assets/images/icon/icon-camera.png')" class="icon-camera-img"/>
                        <div class="full-name-text">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>

                <div class="tribeify-info">
                    <div class="name-text">Hi {{user.firstName}},</div>
                    <div v-if="tabObj.stats === activeTabHref" class="desc-text">
                        View stats for your referrals, surveys and polls, and points/store credit balance.
                    </div>
                    <div v-else-if="tabObj.surveys === activeTabHref" class="desc-text">
                        View available surveys and polls to participate in, and see previous responses to those completed.
                    </div>
                    <div v-else-if="tabObj.refer_a_friend === activeTabHref" class="desc-text">
                        Choose your favourite method of sharing offers with friends with an easy to use sharing tool.
                    </div>
                    <div v-else-if="tabObj.points === activeTabHref" class="desc-text">
                        View available points/store credits and easily apply them to checkout 
                    </div>
                    <div v-else-if="tabObj.right_to_be_forgotten === activeTabHref" class="desc-text">
                        Choose to anonymize all data and personal information collected during participation in the program.
                    </div>
                    <div v-else class="desc-text">
                        View stats for your referrals, surveys and polls, and points/store credit balance.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    import surveyActions from "@/mixins/surveyActions.mixin";
    import advocatePortal from "@/mixins/advocatePortal.mixin";

    // Example Avatar
    import defaultAvatar from "../../assets/images/defaultAvatar.png";

    export default {
        name: "Header",
        mixins: [surveyActions, advocatePortal],
        data() {
            return {
                defaultAvatar,
                userAvatar: null,
                userEmail: {},
                user: {},
                activeTabHref: '',
                tabObj: {},
                shopLogo: window.config.SHOP_LOGO
            };
        },
        async mounted() {
            this.userEmail = await this.getAdvocateEmail;
            this.tabObj = this.getTabObject();
            this.user = await this.getUserData();
            this.userAvatar = this.user?.avatar;
            this.getActiveTabHref();
            window.addEventListener('popstate', this.getActiveTabHref);
        },
        methods: {
            selectFile() {
                this.$refs.avatarImageSelect.click();
            },
            async uploadImage(files) {
                const email = this.userEmail;
                const avatar = new FormData();
                avatar.append("avatar", files[0]);

                const url = `${window.config.TENANT_API_URL}/api/advocate/${email}/avatar`;

                if (avatar) {
                    await axios
                    .post(url, avatar)
                    .then((res) => {
                        this.userAvatar = res.data.avatar;
                    })
                    .catch(console.error());
                }
            },
            getTabObject() {
                return {
                    surveys: 'surveys',
                    points: 'points',
                    stats: 'stats',
                    refer_a_friend: 'refer-a-friend',
                    right_to_be_forgotten: 'right-to-be-forgotten'
                }
            },
            getActiveTabHref() {
                this.activeTabHref = location.hash.replace('#', '');
            },
            goShopping() {
                window.location.href = `${window.config.SHOP_URL}`;
            }
        },
        destroyed () {
            window.removeEventListener('popstate', this.getActiveTabHref)
        },
    };
</script>

<style scoped lang="scss">
    .tribeify-header-container {
        background-color: var(--primary-color);
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-top: 16px;
        padding-bottom: 26px;
        position: fixed;
        width: 100%;
        height: 256px;
        .tribeify-header {
            width: 36%;
            justify-content: center;
            display: flex;
            letter-spacing: 1px;
            color: #fff;
            text-align: center;
            position: relative;

            .shop-logo {
                margin-bottom: 10px;
                img {
                    width: 210px;
                }
            }
            .tribeify-sub-title {
                font-size: 22px;
                opacity: 0.9;
                margin-top: -8px;
            }

            .cart-icon {
                position: absolute;
                top: 4px;
                cursor: pointer;
                left: 0;
                img {
                    width: 38px;
                }
            }

            .tribeify-profile {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: 4px;
                right: 14px;

                .tribeify-profile-image {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    background: #fff;
                    padding: 3px;
                    overflow: hidden;
                    &.medium {
                        height: 40px;
                        width: 40px;
                    }
                    .image-holder {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        overflow: hidden;
                        cursor: pointer;
                        .cover-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        }
                    }
                }

                .full-name-text {
                    font-size: 10px;
                    letter-spacing: 0px;
                    margin-top: -10px;
                }

                .icon-camera-img {
                    width: 14px;
                    height: 14px;
                    transform: translate(14px, -17px);
                }
            }

            .tribeify-info {
                margin-top: 20px;

                .name-text {
                    font-size: 18px;
                }
                .desc-text {
                    font-size: 13px;
                    letter-spacing: normal;
                    opacity: 0.8;
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .tribeify-header-container {
            .tribeify-header {
                width: 100%;
                padding: 0px 12px;

                .tribeify-main-title {
                    font-size: 40px;
                }

                .tribeify-sub-title {
                    font-size: 18px;
                }

                .tribeify-info {
                    text-align: left;
                }
            }
        }
    }
    @media only screen and (max-width: 640px) {
        .tribeify-header {
            .shop-logo {
                img {
                    width: 140px !important;
                }
            }
            .tribeify-sub-title {
                font-size: 12px !important;
            }
            .tribeify-profile {
                right: 10px !important;
            }
            .tribeify-info {
                margin-top: 36px !important;
            }
        }
    }
</style>
