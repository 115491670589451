import { ApiService } from '@/helpers/ApiService';
import { TribeifyApiService } from '@/helpers/TribeifyApiService';

export default {
  data() {
    return {
      apiService: new ApiService(),
      tribeifyApiService: new TribeifyApiService(),
      SURVEY_TYPE: 1,
      POLL_TYPE: 2,
      surveyList: [],
      pollList: [],
      surveyResultList: [],
      pollResultList: [],
      promoteSurveyPollData: '',
      promoteSurveyPollResult: '',
      created_at: '',
      points_credit_rate: 0,
      redeemed_amount: 0,
      share_urls: '',
      referral_list: [],
      total_conversion: 0,
      amount: 0,
      completed_survey_count: 0,
      completed_poll_count: 0,
      tribeifyUser: window.tribeifyUser,
    };
  },
  computed: {
    getAdvocateEmail() {
      return this.tribeifyUser ? this.tribeifyUser.email.toLocaleLowerCase().replace(" ", '+') : undefined;
    },
    getSurveyPollById() {
      return async (id, type) =>
        await this.apiService.get(`customer-survey/available/${id}/`, {
          email: encodeURIComponent(this.getAdvocateEmail),
          type,
        });
    },
    getSurveyPollResultById() {
      return async (id, type) =>
        await this.apiService.get(`customer-survey/complete/${id}/`, {
          email: encodeURIComponent(this.getAdvocateEmail),
          type,
        });
    },
  },
  methods: {
    async getStats() {
      const data = await this.apiService.get(
        `advocate/${encodeURIComponent(this.getAdvocateEmail)}/stats`
      );

      const createdDate = new Date(data.created_at);
      let created_at = createdDate.getUTCFullYear();
      created_at += `/ ${createdDate.getUTCMonth() + 1}`;
      created_at += `/ ${createdDate.getUTCDate()}`;
      this.created_at = created_at;
      this.points_credit_rate = data.points_credit_rate;
      this.redeemed_amount = data.redeemed_amount || 0;
      this.share_urls = data.share_urls;
      this.total_conversion = data.total_conversion || 0;
      this.amount = data.amount || 0;
      this.completed_survey_count = data.completed_survey_count || 0;
      this.completed_poll_count = data.completed_poll_count || 0;
      this.referral_list = data.referrals;
    },
    async getReferralHistory() {
      const temporary_token = 'krisdOkD8ofnhtWC25KvnKJeSBQJ9sE707TQf40A0PV1UF2LMguy1QaY6is4zpfT';
      const data = await this.tribeifyApiService.get(
        `referralsByAdvocate`, {
          advocateEmail: encodeURIComponent(this.getAdvocateEmail),
          access_token: temporary_token
        }
      );
      this.referral_list = data;
    },
    async getSurveyPollList() {
      const data = await this.apiService.get('customer-survey/available', {
        email: encodeURIComponent(this.getAdvocateEmail),
      });
      this.surveyList = data.filter(
        (survey) => survey.type === this.SURVEY_TYPE
      );
      this.pollList = data.filter((survey) => survey.type === this.POLL_TYPE);
    },
    async getSurveyResultList() {
      const data = await this.apiService.get('customer-survey/complete', {
        email: encodeURIComponent(this.getAdvocateEmail),
        type: 1
      });
      this.surveyResultList = data;
    },
    async getPollResultList() {
      const data = await this.apiService.get('customer-survey/complete', {
        email: encodeURIComponent(this.getAdvocateEmail),
        type: 2
      });
      this.pollResultList = data;
    },
    async getSurveyGuest() {
      const data = await this.apiService.get('customer-survey/guest/available');
      return data;
    },
    async getSurveyGuestById(id) {
      const data = await this.apiService.get(`customer-survey/guest/available/${id}/`);
      return data;
    },
    async sendSurveyResult(data) {
      const responseData = await this.apiService.post('survey-result/', data);
      const event = new CustomEvent('update-point', { detail: true });
      window.dispatchEvent(event);
      return responseData;
    },
    getPromoteSurveyPollUrlSearchParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      return params;
    },
    async getPromoteSurveyPollData(id, type) {
      this.promoteSurveyPollData = await this.getSurveyPollById(id, type);
    },
    async getPromoteSurveyPollResult(id, type) {
      [this.promoteSurveyPollResult] = await this.getSurveyPollResultById(
        id,
        type
      );
    },
    showPromoteSurveyPoll(component, method, payload, isPromoteResult) {
      component.isActive = true;
      setTimeout(() => {
        component.$children[0][method](payload, isPromoteResult);
      });
    },
  },
};
