import axios from "axios";
import surveyActions from "@/mixins/surveyActions.mixin";

export default {
  mixins: [surveyActions,],
  methods: {
    queryStringToJsonObj(queryString) {
      if(queryString.indexOf('?') > -1){
        queryString = queryString.split('?')[1];
      }
      var pairs = queryString.split('&');
      var result = {};
      pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      });
      return result;
    },
    async getUserData() {
      this.userEmail = await this.getAdvocateEmail;
      const response = await axios.get(`${window.config.TENANT_API_URL}/api/advocate/${this.userEmail}`);
      return response.data;
    },
    decodeHtml(html) {
      var text = document.createElement("textarea");
      text.innerHTML = html;
      return text.value;
    },
  }
}