<template>
  <div class="tribeify-survey-result">
    <slot />
    <div
      v-for="(answer, question) in surveyResultContent"
      :key="question"
      class="tribeify-survey-result-item"
    >
      <div class="tribeify-survey-question">
        {{ getQuestionTitle(question) }}
      </div>
      <div class="tribeify-survey-answer">
        <template v-if="isMultipleAnswer(answer)">
          <div v-for="item in answer" :key="item">{{ item }}</div>
        </template>
        <template v-else>{{ answer }}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyResult",
  props: {
    surveyResult: Object,
  },
  data() {
    return {
      surveyResultContent: Object,
    };
  },
  beforeMount(){
    this.surveyResultContent = Object.fromEntries(Object.entries(this.surveyResult.content).filter(([key]) => key.toLowerCase() !== 'opt-in text'));
  },
  computed: {
    isMultipleAnswer() {
      return (answer) => {
        return answer instanceof Array;
      };
    },
    getQuestionTitle() {
      return (questionName) => this.getQuestionByName(questionName);
    },
  },
  methods: {
    getRenderText(text, context) {
      let renderedText = text;
      Object.entries(context).map(([key, value]) => {
        renderedText = renderedText.replaceAll(`{{${key}}}`, value)
      });
      return renderedText;
    },
    getQuestionByName(name) {
      let questionName = this.surveyResult.survey.content.pages
        .map((page) => {
          const elements = page.elements || []; 
          const result = elements.find((element) => {
            if (element.name === name) return element.title;
          });
          if (result) return result.title;
        })
        .find((question) => !!question);
      questionName = this.getRenderText(
        questionName, 
        { 
          offer_value: (this.surveyResult.points / 100),
          coupon_code: this.surveyResult.coupon_code,
          point: this.surveyResult.points
        }
      );
      return questionName;
    },
  },
};
</script>

<style lang="scss" scoped>
.tribeify-survey-result-item {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 2px solid #e5e5e5;
  .tribeify-survey-question {
    font-weight: bold;
  }
  .tribeify-survey-question,
  .tribeify-survey-answer {
    flex: 1 1 100%;
    padding: 4px 8px;
  }
}

@media (max-width: 576px) {
  .tribeify-survey-result-item {
    flex-wrap: wrap;
  }
}
</style>