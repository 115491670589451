<template>
  <div class="referral-history-container">
    <Title title="Referral History" />
    
    <div class="content-wrapper">
      <div class="tbl-referral-history" v-if="0 < referrals.length">
        <v-client-table :columns="columns" v-model="referrals" :options="options">
          <div slot="contactFirstName" slot-scope="props">
            <div v-if="props.row.contactFirstName && props.row.contactLastName">
              {{ props.row.contactFirstName }} {{ props.row.contactLastName }}
            </div>
            <div v-else>{{ props.row.email }}</div>
          </div>
          <div slot="utm_source" slot-scope="props">
            <div v-if="props.row.utm_source">
              {{ 'referlink' == props.row.utm_source ? 'Refer link' : props.row.utm_source }}
            </div>
            <div v-else></div>
          </div>
          <div slot="advocateRewardPoint" slot-scope="props">
              {{ props.row.advocateRewardPoint }} pts.
          </div>
          <div slot="dateAcquiredReferralPartner" slot-scope="props">
              {{ getResultDateFormat(props.row.dateAcquiredReferralPartner) }}
          </div>
        </v-client-table>
      </div>
      <div v-else class="text-center my-3">No Data</div>
    </div>
  </div>
</template>

<script>
  import dateActions from "@/mixins/dateActions.mixin";
  import Title from "../common/title";

  export default {
    name: "ReferralHistory",
    mixins: [dateActions],
    components: { Title, },
    props:  {
      referrals: Array,
    },
    data() {
      return {
        columns: ['contactFirstName', 'utm_source', 'advocateRewardPoint', 'dateAcquiredReferralPartner'],
        options: {
          headings: {
            contactFirstName: 'Name',
            utm_source: 'Source',
            advocateRewardPoint: 'Reward',
            dateAcquiredReferralPartner: 'Date'
          },
          filterable: false,
          pagination: {
            show: true,
          },
        },
      }
    },
  };
</script>

<style lang="scss">
  .referral-history-container {
    .content-wrapper {
      .tbl-referral-history {
        .VueTables__table {
          opacity: 0.7;

          thead, tbody {
            tr {
              border: 0;
              border-bottom: 1px solid #DDD;

              th, td {
                border: 0;
                padding-left: 0;
              }
            }
          }
          tbody {
              tr {
                &:hover {
                  background-color: #F8F9F9;
                }

                td {
                  padding-left: 3px;
                  box-shadow: unset;

                  .share-text {
                    margin-left: -4px;
                  }
                }
              }
            }
        }

        .VuePagination__pagination {
          justify-content: end;
          li {
            a {
              font-size: 9px;
              &.active {
                background-color: var(--primary-color);
              }
            }
          }
        }

        .VuePagination__count, .VueTables__limit-field {
          display: none;
        }

      }
    }
  }
</style>
