<template>
    <div class="landing-page-header">
      <div class="merchant-logo">
        <img :src="shopLogo"/>
      </div>
      <div class="header-sub-title">Membership Rewards</div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      shopLogo: window.config.SHOP_LOGO
    };
  },
  async mounted() {
    await this.$getBrandAttributes();
    this.primaryColor = this.$brandAttributes.primaryColor;
    
    document.body?.style.setProperty('--primary-color', this.primaryColor);

    this.removeMainBodyLoadingMsgEle();
  },
  methods: {
    removeMainBodyLoadingMsgEle() {
      let msgEle = document.querySelector(".tribeify-main-body-loading-msg");
      msgEle?.remove();
    },
  }
};
</script>

<style scoped lang="scss">
  .landing-page-header {
    background-color: var(--primary-color);
    height: 200px;
    .merchant-logo {
      padding-top: 38px;
      text-align: center;
      padding-bottom: 5px;

      img {
        width: 200px;
      }
    }
    .header-sub-title {
      font-size: 22px;
      opacity: 0.9;
      color: #fff;
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    .landing-page-header {
      height: 120px;
      .merchant-logo {
        padding-top: 10px;
      }
    }
  }
</style>