<template>
  <div class="tribeify-stats">
    <Title title="Stats" />
    <IconCard
      title="Click the stats below or the menu above to easily access all areas of your portal"
    />

    <div class="content-wrapper">
      <div>
        <div class="tribeify-stats-item">
          <a href="#referral-history" class="item-title">REFERRALS</a>
          <div class="item-detail">
            <span class="tribeify-section-item-value"><b>{{ totalConversion }}</b></span>
            <span class="tribeify-section-item-title">successful referrals</span>
          </div>
        </div>

        <div class="tribeify-stats-item">
          <a href="#surveys" class="item-title">SURVEYS/POLLS</a>
          <div class="item-detail">
            <span class="tribeify-section-item-value"><b>{{ completedSurveyCount }}</b></span>
            <span class="tribeify-section-item-title">Survey completed</span>
          </div>
          <div class="item-detail">
            <span class="tribeify-section-item-value"><b>{{ completedPollCount }}</b></span>
            <span class="tribeify-section-item-title">Polls completed</span>
          </div>
        </div>

        <div class="tribeify-stats-item">
          <a href="#points" class="item-title">POINTS/STORE CREDITS</a>
          <div class="item-detail">
            <span class="tribeify-section-item-value">
              <b> {{ amount + redeemedAmount }}/${{ ((amount + redeemedAmount) * pointsCreditRate).toFixed(2) }}</b>
            </span>
            <span class="tribeify-section-item-title">Points/Store Credit Earned</span>
          </div>
          <div class="item-detail">
            <span class="tribeify-section-item-value">
              <b>{{ amount }}/${{ (amount * pointsCreditRate).toFixed(2) }}</b>
            </span>
            <span class="tribeify-section-item-title">Points/Store Credit Available</span>
          </div>
          <div class="item-detail">
            <span class="tribeify-section-item-value">
              <b>{{ redeemedAmount }}/${{ (redeemedAmount * pointsCreditRate).toFixed(2) }}</b>
            </span>
            <span class="tribeify-section-item-title">Points/Store Credits Redeemed</span>
          </div>
        </div>
      </div>

      <div><img :src="require('../../assets/images/people-01.png')" class="stats-img"/></div>
    </div>
  </div>
</template>

<script>
  import Title from "../common/title";
  import IconCard from "../common/infoCard";

  export default {
    name: "Stats",
    components: { Title, IconCard },
    props: [
      "totalConversion",
      "completedSurveyCount",
      "completedPollCount",
      "amount",
      "redeemedAmount",
      "pointsCreditRate"
    ],
  };
</script>

<style scoped lang="scss">
  .tribeify-stats {
    justify-content: left;
    flex-wrap: wrap;
    .tribeify-stats-item {
      align-items: center;
      padding: 12px 0 16px 0;
      .tribeify-section-item-title {
        flex: 1 1 30%;
        padding: 4px 8px;
      }
      &:last-child {
        border-bottom: none;
      }
      .tribeify-section-item-value {
        font-size: 17px;
        .tribeify-stats-url {
          font-size: 12px;
          overflow-wrap: anywhere;
        }
        .tribeify-cta-copy {
          display: flex;
          cursor: pointer;
          text-align: center;
          align-items: center;
          background: transparent;
          font-size: 12px;
          .tribeify-icon-copy {
            display: inline-flex;
            width: 20px;
          }
        }
      }
      .tribeify-section-item-value-start {
        justify-content: flex-start;
      }
      .tribeify-section-item-value-center {
        justify-content: flex-start;
      }
      .item-title {
        font-size: 18px;
        font-weight: 500;
        color: var(--primary-color);
        text-decoration: none;
      }
      .item-detail {
        letter-spacing: 0.01em;
        margin-top: 0.25rem;
      }
    }

    .tribeify-stats-item-mod {
      font-weight: bold;
    }

    .tribeify-stats-item-heading {
      margin-top: 20px;
      border-bottom: none;
      font-weight: bold;
    }
    .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
    }
    .stats-img {
      width: 104px;
      margin-right: 60px;
      margin-top: 30px;
    }
  }

  .tribeify-section-item-value-expired {
    color: red;
  }

  .tribeify-section-item-value-flex-start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 30%;
    padding: 4px 8px;
    .tribeify-stats-url {
      font-size: 12px;
      overflow-wrap: anywhere;
      padding-right: 10px;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }
    .tribeify-cta-copy {
      display: flex;
      cursor: pointer;
      text-align: center;
      align-items: center;
      justify-content: flex-end;
      background: transparent;
      font-size: 12px;
      @media screen and (min-width: 641px) {
        display: none;
      }
      .tribeify-icon-copy {
        display: inline-flex;
        width: 20px;
        @media screen and (min-width: 641px) {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .tribeify-stats {
      .stats-img {
        width: 66px;
        margin-top: 20px;
        margin-right: 42px;
      }
      .tribeify-stats-item {
        justify-content: space-between;
      }
    }
  }
</style>
