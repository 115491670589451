<template>
  <div class="tribeify-point-store-credit">
    <Title title="Points/Store Credits" />
    <IconCard
      title="Specify how many points you would like to redeem towards your purchase. 
      Equivalent store credit value will automatically be applied to your checkout page."
    />

    <div class="content-wrapper">
      <div>
        <div class="tribeify-points">{{ tribeifyUser.amount || 0 }} points available</div>
        <!-- <div class="tribeify-pending-points">
          {{ tribeifyUser.pendingAmount }} pending points
        </div> -->
        <p class="tribeify-available-store-credit">
          ${{( (tribeifyUser.amount * tribeifyUser.pointCreditRate).toFixed(2) )}} in store credits available
        </p>
        <h4 class="tribeify-redeem-store-credit-text">Redeem your store credits</h4>
        <div class="tribeify-point-amount">
          <input
            ref="pointsAmount"
            type="number"
            min="1"
            :max="tribeifyUser.amount"
            required
            :disabled="!tribeifyUser.amount"
            class="tribeify-input"
            :class="[pointsAmountClass, { 'tribeify-disabled': !tribeifyUser.amount }]"
            v-model="inputedAmount"
            placeholder="Enter points"
            @input="onPointsAmount"
            @focus="onPointsAmountFocus"
          />
          <div
            v-if="pointAmountInvalidFeedback || !tribeifyUser.amount"
            class="tribeify-invalid-feedback"
          >
            {{
              pointAmountInvalidFeedback
                ? pointAmountInvalidFeedback
                : "No store points available"
            }}
          </div>
        </div>
        <p class="tribeify-points-conversion">
          Conversion rate - 100 points = ${{ 100 * tribeifyUser.pointCreditRate }} in store credits
        </p>
        <Alert
          v-if="redeemPointsStatus.toString().length && redeemPointsStatus"
          variant="success"
          class="tribeify-points-success-message"
        >
          Points Applied Successfully
        </Alert>
        <Alert
          v-if="redeemPointsStatus.toString().length && !redeemPointsStatus"
          variant="danger"
          class="tribeify-points-error-message"
        >
          Failed to Apply Points / Please Check Your Points
        </Alert>
        <div class="tribeify-button-wrapper">
          <button
            class="tribeify-button btn-apply"
            :class="[
              {
                'tribeify-disabled':
                  !tribeifyUser.amount ||
                  pointAmountInvalidFeedback ||
                  !inputedAmount ||
                  isRedeemStatusPending,
              },
            ]"
            :disabled="
              pointAmountInvalidFeedback || !inputedAmount || isRedeemStatusPending
            "
            @click="redeemPoints()"
          >
            Apply
          </button>
          <button
            class="tribeify-button btn-go-shopping"
            @click="goShopping()"
          >
            Go shopping
          </button>
        </div>
      </div>
      <div class="point-img">
        <img :src="require('../../assets/images/people-02.png')"/>
      </div>
    </div>

  </div>
</template>
  
<script>
  import Alert from "@/components/common/Alert";
  import Title from "../common/title";
  import IconCard from "../common/infoCard";

  export default {
    name: "PointsStoreCredits",
    components: { Alert, Title, IconCard },
    data() {
      return {
        tribeifyUser: {},
        inputedAmount: undefined,
        pointAmountInvalidFeedback: "",
        isRedeemStatusPending: false,
        redeemPointsStatus: "",
        shoppingUrl: "",
        shopUrl: ""
      };
    },
    computed: {
      pointsAmountClass() {
        return this.pointAmountInvalidFeedback ? "tribeify-invalid" : "";
      }
    },
    methods: {
      redeemPoints() {
        this.isRedeemStatusPending = true;

        const event = new CustomEvent("redeem-point", {
          detail: this.inputedAmount,
        });
        window.dispatchEvent(event);
        this.inputedAmount = "";
      },
      goShopping() {
        window.location.href = this.shoppingUrl;
      },
      onPointsAmountFocus() {
        this.redeemPointsStatus = "";
      },
      onStatusMessage(event) {
        this.isRedeemStatusPending = false;
        this.redeemPointsStatus = event.detail?.status;
        if (event.detail?.couponCode === undefined) {
          return;
        }
        this.shoppingUrl = `${this.shopUrl}?couponCode=${event.detail?.couponCode}`;
      },
      onPointsAmount(event) {
        if (event.target.validity.valid) {
          this.pointAmountInvalidFeedback = "";
          return;
        }
        this.showPointsAmountError(event);
      },
      showPointsAmountError({ target }) {
        if (target.validity.valueMissing) {
          this.pointAmountInvalidFeedback = "This field is required";
          return;
        }
        this.pointAmountInvalidFeedback =
          "The value must be greater than 0 and less than or equal to store points";
      },
    },
    mounted() {
      this.tribeifyUser = window.tribeifyUser || {};
      this.shopUrl = `${window.config.SHOP_URL}`;
      this.shoppingUrl = this.shopUrl;
      window.addEventListener("point-redeem-status", this.onStatusMessage);
    },
    destroyed() {
      window.removeEventListener("point-redeem-status", this.onStatusMessage);
    },
  };
</script>

<style scoped lang="scss">
  .tribeify-point-store-credit {
    display: flex;
    flex-direction: column;
    .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
    }
    .tribeify-points {
      font-size: 26px;
      margin-top: 12px;
    }
    .tribeify-pending-points {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .tribeify-available-store-credit {
      font-size: 15px;
    }
    .tribeify-redeem-store-credit-text {
      font-size: 16px;
      margin-top: 8px;
    }
    .tribeify-points-conversion {
      font-size: 12px;
    }
    .tribeify-point-amount {
      width: 185px;
      margin-bottom: 8px;
      .tribeify-input {
        margin: 0;
        background: transparent;
        font-size: 12px;
      }
    }
    .tribeify-points-success-message,
    .tribeify-points-error-message {
      max-width: 318px;
      margin-bottom: 16px;
    }
    .tribeify-points-apply {
      margin-bottom: 16px;
    }
    .tribeify-button-wrapper {
      display: flex;
      margin-top: 40px;
      margin-bottom: 30px;
      .tribeify-button {
        background-color: var(--primary-color);
      }
      .btn-apply {
        width: 140px;
        margin-right: 24px;
      }
      .btn-go-shopping {
        width: 104px;
      }
    }
    
    .point-img {
      img {
        width: 180px;
        margin-right: 60px;
        margin-top: 30px;
      }
    }
  }

  @media screen and (min-width: 640px) {
    .tribeify-point-store-credit {
      .tribeify-points-apply {
        width: 320px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .tribeify-point-store-credit {
      .content-wrapper {
        flex-direction: column;
      }

      .tribeify-button-wrapper {
        display: flex;
        flex-direction: column;
        .btn-apply {
          margin-right: 0px;
        }
        .btn-go-shopping {
          margin-top: 24px;
        }
      }

      .point-img {
        display: flex;
        justify-content: right;
        margin-top: -140px;
        margin-bottom: 16px;
        img {
          width: 130px;
        }
      }
    }
  }
</style>