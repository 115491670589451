<template>
    <div class="tribeify-footer-container">
        <div class="footer-text-wrapper container">
            <a v-if="!tribeifyUser.anonymize" @click="onNavigationClicked" href="#right-to-be-forgotten">Your right to be forgotten</a>

            <div class="right-content">
                <img @click="toTop" :src="require('../../assets/images/icon/icon-pyramit.png')" class="scroll-up-img"/>
                <small><a href="http://neopath.io/">Powered by neopath</a></small>
            </div>
        </div>
    </div>
</template>

<script>
    import surveyActions from "@/mixins/surveyActions.mixin";

    export default {
        name: "Footer",
        mixins: [surveyActions,],
        data() {
            return {};
        },
        methods: {
            toTop: function () {
                document.getElementById('tribeify_content_id').scrollTo(0,0);
            },
            onNavigationClicked: function () {
                // window.history.replaceState({}, document.title, '/#right-to-be-forgotten');
            }
        },
    };
</script>

<style scoped lang="scss">
    .tribeify-footer-container {
        background-color: var(--primary-color);
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1;
        .footer-text-wrapper {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 6px;

            a {
                color: #FFFFFF;
            }
            .right-content {
                margin-left: auto;
                .scroll-up-img {
                    width: 26px;
                    position: absolute;
                    top: -38px;
                    cursor: pointer;
                    left: calc(50% + 400px);
                    opacity: 0.6;
                }
            }
        }
    }
    @media only screen and (max-width: 640px) {
        .tribeify-footer-container {
            .footer-text-wrapper {
                width: 100%;
                .scroll-up-img {
                    display: none;
                }
            }
        }
    }
</style>
