<template>
  <div class="refer-a-friend-container">
    <Title title="Refer a friend" />
    <IconCard
      title="Refer your friends to earn and redeem rewards points when making purchases."
    />
    <div class="content-wrapper pt-2" v-if="tribeify_iframe_src">
      <iframe id="advocateTribeifyFrontendIframe" :src="tribeify_iframe_src" height="700px" width="100%"></iframe>
      <div class="loading-iframe-src mb-5">Loading...</div>
    </div>
  </div>
</template>

<script>
  import Title from "../common/title";
  import IconCard from "../common/infoCard";
  import advocatePortal from "@/mixins/advocatePortal.mixin";

  export default {
    name: "ReferAFriend",
    mixins: [advocatePortal, ],
    components: { Title, IconCard },
    data() {
      return {
        tribeify_iframe_src: '',
      }
    },
    created () {
      this.tribeify_iframe_src = window.config.FRONTEND_REFER_URL;
    },
    mounted () {
      this.tribeify_iframe_src = window.config.FRONTEND_REFER_URL;
    },
  };
</script>

<style scoped lang="scss">
  .refer-a-friend-container {
    .content-wrapper {
      position: relative;
    }
    #advocateTribeifyFrontendIframe {
      background-color: transparent;
      position: relative;
      z-index: 1;
    }
    .loading-iframe-src {
      position: absolute;
      text-align: center;
      font-size: 18px;
      display: inline-block;
      width: 100%;
      top:20%;
      left: 0;
      z-index: 0;
    }
  }
</style>
