<template>
  <div>
    <div class="tribeify-accordion">
      <Tabs ref="tabsRef">
        <TabItem href="#stats" name="Stats" :selected="activeTabHref === 'stats'"></TabItem>
        <TabItem href="#surveys" :selected="activeTabHref === 'surveys'" name="Surveys/Polls"></TabItem>
        <TabItem href="#refer-a-friend" :selected="activeTabHref === 'refer-a-friend'"  name="Refer a friend"></TabItem>
        <!-- <TabItem :href="invite_url+'#refer-a-friend'" :selected="activeTabHref === 'refer-a-friend'"  name="Refer a friend"></TabItem> -->
        <TabItem href="#points" :selected="activeTabHref === 'points'" name="Points/Store Credits"></TabItem>
      </Tabs>
      
      <div class="mobile-advocate-content">
        <div class="stats card" v-if="activeTabHref === 'stats'">
          <Stats 
            :totalConversion="total_conversion"
            :completedSurveyCount="completed_survey_count"
            :completedPollCount="completed_poll_count"
            :amount="amount"
            :redeemedAmount="redeemed_amount"
            :pointsCreditRate="points_credit_rate"
          />
        </div>

        <!-- Surveys/Polls section -->
        <div v-if="activeTabHref === 'surveys'">
          <div class="surveys card"><Surveys type="survey"/></div>
          <div class="survey-img text-center">
            <img :src="require('../../assets/images/people-06.png')" class="img-people-06" />
          </div>
          <div class="polls card"><Surveys type="poll" /></div>
        </div>
        <!-- End surveys/polls section -->

        <!-- Refer a friend section -->
        <div v-if="activeTabHref === 'refer-a-friend' && !tribeifyUser.anonymize">
          <div class="refer-a-freind card">
            <ReferAFriend />
          </div>
          <div class="refer-a-friend-img">
            <img :src="require('../../assets/images/people-05.png')" class="img-people-05" />
            <img :src="require('../../assets/images/people-04.png')" class="img-people-04 ms-4" />
          </div>
        </div>

        <div class="referral-history card"
          v-if="activeTabHref === 'referral-history' || activeTabHref === 'refer-a-friend'">
          <ReferralHistory :referrals="referral_list"/>
        </div>

        <div v-if="activeTabHref === 'refer-a-friend' && !tribeifyUser.anonymize">
          <div class="unique-sharing-url card mt-3">
            <UniqueSharingUrl :shareUrls ="share_urls"/>
          </div>
        </div>
        <!-- End refer a friend section -->

        <div class="points-store-credits card" v-if="activeTabHref === 'points'"><PointsStoreCredits /></div>

        <div class="right-to-be-forgotten card"
          v-if="activeTabHref === 'right-to-be-forgotten' && !tribeifyUser.anonymize">
          <RightToBeForgotten />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  // import AccordionItem from "../common/accordionItem";
  import RightToBeForgotten from "../sections/rightToBeForgotten";
  import Surveys from "../sections/surveys";
  import Stats from "../sections/stats";
  import ReferAFriend from "../sections/referAFriend";
  import ReferralHistory from "../sections/referralHistory";
  import UniqueSharingUrl from "../sections/uniqueSharingUrl";
  import surveyActions from "@/mixins/surveyActions.mixin";

  import PointsStoreCredits from "../sections/pointsStoreCredits";
  import Tabs from "../common/tabs";
  import TabItem from "../common/tabItem";
  // @TODO: implement tabs
  // import Notifications from '../sections/notifications'
  // import Offers from '../sections/offers'
  // import Friends from '../sections/friends'

  export default {
    name: "Content",
    mixins: [surveyActions,],
    components: {
      // AccordionItem,
      PointsStoreCredits,
      // Notifications,
      // Offers,
      RightToBeForgotten,
      Surveys,
      // Friends,
      Stats,
      Tabs,
      TabItem,
      ReferAFriend,
      ReferralHistory,
      UniqueSharingUrl
    },
    data() {
      return {
        activeTabHref: '',
        defaultTabHref: 'stats',
        invite_url: localStorage.getItem('advocate-invite-url')
      };
    },
    methods: {
      setNewActiveTabHref() {
        this.activeTabHref = location.hash.replace('#', '')  || this.defaultTabHref;
      }
    },
    async mounted () {
      this.setNewActiveTabHref()
      window.addEventListener('popstate', this.setNewActiveTabHref);
      window.addEventListener("update-point", this.getStats, false);
      await this.getStats();
    },
    destroyed () {
      window.removeEventListener('popstate', this.setNewActiveTabHref);
      window.removeEventListener("update-point", this.getStats, false);
    },
  };
</script>

<style lang="scss">
  .tribeify-accordion {
    .card {
      border: 1px solid #eee;
      padding: 12px;
      border-radius: 5px;
      background-color: #FFFFFF;
    }

    .survey-img {
      img {
        width: 102px;
        margin-top: 14px;
        margin-bottom: 14px;
      }
    }

    .refer-a-friend-img {
      text-align: center;
      .img-people-05 {
        width: 50px;
      }
      .img-people-04 {
        width: 50px;
        margin-top: 50px;
      }
    }

    .tribeify-tabs-wrapper {
      border-bottom: 5px solid #ddd !important;
      border-top: 5px solid #ddd;

      .tribeify-tabs {
        display: flex;
        width: 100% !important;

        a {
          font-size: 15px !important;
          padding: 10px 0px !important;
        }

        // &.is-active {
        //   border-radius: 19px;
        //   background: #e7f3ff;
        // }
      }
    }

    .mobile-advocate-content {
      margin: 34px 9px 26px 9px;
    }
  }

  @media (max-width: 576px) {
    .tribeify-accordion {
      .right-to-be-forgotten {
        .right-to-be-forgotten-img {
          display: none;
        }
      }

      .stats {
        .stats-img {
          position: absolute;
          right: 0;
        }
      }

      .refer-a-friend-img {
        margin-bottom: 18px;
      }
    }
  }
</style>
