<template>
  <div class='tribeify-layout'>
    <Header />
    <Content />
    <Footer />
  </div>
</template>

<script>
import "../globalMixins";

import Header from './layout/header'
import Content from './layout/content'
import Footer from './layout/footer';

export default {
  name: 'Main',
  components: {
    Header, 
    Content,
    Footer
  },
  async mounted() {
    await this.$getBrandAttributes();
    this.bodyCopyFont = this.$brandAttributes.bodyCopyFont;
    this.headingFont = this.$brandAttributes.headingFont;
    this.primaryColor = this.$brandAttributes.primaryColor;
    this.secondaryColor = this.$brandAttributes.secondaryColor;
    
    document.body?.style.setProperty('--primary-color', this.primaryColor);
    document.body?.style.setProperty('--secondary-color', this.secondaryColor);
    document.body?.style.setProperty('font-family', this.bodyCopyFont);
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.tribeify-layout {
  display: flex;
  flex-direction: column;
}
</style>